/* eslint-disable react/no-unescaped-entities */
import React from "react";

function NotFound() {
  return (
    <div className="mt-16 mx-auto w-full">
      <h1 className="text-4xl text-center animate-pulse">Her var det ingen oppgave!</h1>
      <img className="mx-auto mt-12 animate-spin-slow" src="https://media.giphy.com/media/mJhRSYXxzq6CA0ldkh/giphy.gif" alt="WRONG" />
      <p className="mt-16 text-center animate-wiggle">Nei, her tar faktisk Seth feil. </p>
    </div>
  );
}

export default NotFound;
