const serializers = {
  types: {
    image: (props) => {
      const { asset } = props.node
      if (!asset) return null

      return (
        <figure className="my-2 md:my-8">
          <img className="w-full" alt="et bilde" src={asset.url} />
        </figure>
      )
    },
  },
}

export default serializers