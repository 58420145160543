import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { sanity } from "../client"
import BlockContent from '@sanity/block-content-to-react'
import serializers from "../serializers"
import NotFound from "./NotFound"
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

export default function Task () {
  const { slug } = useParams()
  const query = '*[_type == "post" && slug.current == $slug]{_id,title,gif,body[]{...,_type == "image" => {asset->{url}}}}'
  const { data: task } = useQuery(`${slug}Query`, () =>
    sanity.fetch(query, { slug })
  )
  const { width, height } = useWindowSize()


  if(!task) return <p>laster oppgave...</p>
  if(typeof task[0] === 'undefined') return <NotFound />
  const {body, title, _id, gif} = task[0]


  return (
    <div key={_id} className="mx-auto">
      {slug.includes('hurra') && (
        <Confetti height={height} width={width} numberOfPieces={400} />
      )}
      <h1 className="text-3xl font-bold text-center mt-16 animate-bounce">{title}</h1>
      <BlockContent className="mt-12 text-center" blocks={body} serializers={serializers} />
      {gif && (
        <img className="mx-auto mt-12" src={gif} alt="Amazing gif" />
      )}
    </div>
  )
}