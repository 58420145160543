import React from 'react';
import { Routes, Route } from "react-router-dom";
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Task from './pages/Task';
import Layout from './pages/Layout';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<Home />} />
        <Route path=":slug" element={<Task />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
